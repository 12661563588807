import config from "../services/config";

import { createAuth0Client } from "@auth0/auth0-spa-js";

const auth0Client = createAuth0Client({
  domain: config.domain,
  clientId: config.client_id,
  authorizationParams: {
    audience: config.audience,
    scope: config.scope,
    redirect_uri: config.redirect_uri,
  },
  prompt: "login",
  useFormData: "false",
});

export default auth0Client;
