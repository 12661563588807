function _getElementByScope(schema, scope) {
    // Rimuove il carattere # all'inizio dello scope e divide il percorso
    const paths = scope.replace(/^#/, "").split("/").filter((p) => p !== "");
    let element = schema;
    for (const path of paths) {
      // Verifica se l'elemento esiste prima di tentare di accedervi
      if (element && typeof element === "object" && path in element) {
        element = element[path];
      } else {
        // Elemento non trovato, restituisce undefined o gestisci come preferisci
        return undefined;
      }
    }
    return element;
  }

  
  export const getElementByScope=_getElementByScope;