<template>
    <vue-monaco-editor
        v-model="internalValue.code"
        height="600px"
        language="csharp"
        loading="false"
        @change="change"
      />
</template>

<script>
import Vue from "vue";
import {defineComponent} from "vue"
export default defineComponent({
  name: "CsharpCode",
  components: {
  },
  props: ["value"],
  setup: function () {},
  beforeMount: async function () {
    console.log(this.value);
    this.internalValue=this.value;
    
  },
  methods: {
    change: async function(){
        this.$emit("input", this.internalValue);
    }
  },
  data: function () {
    return {
        internalValue:{}
    };
  },
});
</script>
