import Vue from "vue";
import Axios from "axios";
import VueAxios from "vue-axios";

//TODO: marco: questo non ha più senso di esistere ora
// axios.interceptors.request.use(request => {
//   let userToken=localStorage.getItem('userToken');
//   if (userToken) {
//       request.headers.Authorization = `${userToken}`;
//   }
//   return request;
// });

//TODO: Marco: questo dovrebber garantire il redirect  sul login
Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && 401 === error.response.status) {
      document.location.href = "/login";
    } else {
      return Promise.reject(error);
    }
  }
);

export default function configAxios() {
  Vue.use(VueAxios, Axios);
}
export const axios=Axios;