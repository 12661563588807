import Vue from "vue";
const EventBus = new Vue();
const url = "/api/handler";
import userService from "../services/user";
import config from "../services/config";
import moment from 'moment';
import {axios} from './axios';

const HttpClient={
  // loading:function (loading){
  //   this.isLoading=loading;
  // },
  //isAuthenticated: function () {
  //  return localStorage.getItem('userToken')!=null;
  //},
  getFormattedDate: function (date, format) {
    return moment(date).format(format)
  },
  showError: function (message) {
    EventBus.$emit("snackbar:push", {
      type: "error",
      message: message,
      timeout: 60000,
    });
  },
  showSuccess: function (message) {
    EventBus.$emit("snackbar:push", {
      type: "success",
      message: message,
      timeout: 2000,
    });
  },
  showSaving: function (message) {
    EventBus.$emit("snackbar:push", {
      type: "saving",
      message: message,
      timeout: 60000,
    });
  },
  showWarning: function (message) {
    EventBus.$emit("snackbar:push", {
      type: "warning",
      message: message,
      timeout: 60000,
    });
  },
  listen: function (event, sender, func) {
    if(func==null)
    {
      console.error("unable to register event"+event)
    }
    EventBus.$on(event, function (data) {
      return func(sender, data);
    });
  },
  send: function (event, data) {
    EventBus.$emit(event, data);
  },
  simplifyResponse: function (response) {
    if (response.status == 200) {
      return response.data;
    }

    return {
      hasError: false,
      errorMessage: "error" + response.status,
    };
  },
  insertOrUpdate: async function (entity, data) {
    let id = data._id && data._id != "";
    delete data._meta;
    if (id) {
      return this.simplifyResponse(
        await this.apiCall(url, "PUT", data, { collection: entity })
      );
    } else {
      return this.simplifyResponse(
        await this.apiCall(url, "POST", data, { collection: entity })
      );
    }
  },
  patch: async function (entity, data) {
    delete data._meta;
    return this.simplifyResponse(
      await this.apiCall(url, "PATCH", data, { collection: entity })
    );
  },
  remove: async function (entity, data) {
    return (
      (
        await this.apiCall(url, "DELETE", params, {
          id: data._id,
          collection: entity,
        })
      ).status === 200
    );
  },
  get: async function (entity, id) {
    return this.simplifyResponse(
      await this.apiCall(url, "GET", null, { collection: entity, id: id })
    );
  },
  searchExt: async function (entity, filter, projection) {
    return this.simplifyResponse(
      await this.apiCall(url, "GET", null, {
        collection: entity,
        query: JSON.stringify(filter) ?? "{}",
        projection: JSON.stringify(projection) ?? "{}",
      })
    );
  },
  aggregate: async function (entity, filter) {
    return this.simplifyResponse(
      await this.apiCall(url, "GET", null, {
        collection: entity,
        query: {},
        aggregate: JSON.stringify(filter ?? "{}"),
        projection: {},
      })
    );
  },
  getUserHeaders() {
    let user = userService.user;
    if (user.loginType == "Oauth") {
      return {
        Authorization: "Bearer " + user.token,
        "x-tenant-id": user.tenant, //dovrebbe prenderlo dal token?
      };
    } else if (user.loginType == "ApiKey") {
      return {
        "x-api-key": user.token,
        "x-tenant-id": user.tenant,
      };
    }
  },
  getEgressUrl() {
    let url = localStorage.getItem("egressUrl");
    if (url && url.length !== 0) {
      return url;
    }
    return config.egressUrl;
  },
  getSchedulerUrl() {
    let url = localStorage.getItem("schedulerUrl");
    if (url && url.length !== 0) {
      return url;
    }
    return config.ingressScheduledUrl;
  },
  getWebhookUrl() {
    let url = localStorage.getItem("webhookUrl");
    if (!url || url.length === 0) {
      url = config.ingressWebUrl;
    }
    return (url.endsWith("/") ? url.slice(0, -1) : url) + "/";
  },
  getApiUrl() {
    let url = localStorage.getItem("apiUrl");
    if (url && url.length !== 0) {
      return url;
    }
    return config.apiUrl;
  },
  egressCall: async function (url, method, body, query) {
    let baseUrl = this.getEgressUrl();
    return await this.httpRequest(baseUrl + url, method, body, query, {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      ...this.getUserHeaders(),
    });
  },
  schedulerCall: async function (url, method, body, query) {
    let baseUrl = this.getSchedulerUrl();
    return await this.httpRequest(baseUrl + url, method, body, query, {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      ...this.getUserHeaders(),
    });
  },
  apiCall: async function (url, method, body, query) {
    let baseUrl = this.getApiUrl();
    return await this.httpRequest(baseUrl + url, method, body, query, {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      ...this.getUserHeaders(),
    });
  },
  apiCallWithExtraHeaders: async function (url, method, body, query,headers) {
    let baseUrl = this.getApiUrl();
    return await this.httpRequest(baseUrl + url, method, body, query, {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      ...this.getUserHeaders(),
      ...headers
    });
  },
  apiCallForUpload: async function(url,file, query,headers) {
    let baseUrl = this.getApiUrl();
    return await this.httpRequest(baseUrl + url, "POST", file, query, {
      "Content-Type": 'multipart/form-data',
      ...this.getUserHeaders(),
      ...headers
    });
  },
  apiCallForDownload: async function (name,urlInternal, method, body, query) {
    let baseUrl = this.getApiUrl();
    const response =  await this.httpRequestForFileDownload(baseUrl + urlInternal, method, body, query, {
      ...this.getUserHeaders(),
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    let fileName = name + '.json';
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  },
  httpRequestInternal: async function (options) 
  {
    try {
      const response = await this.axios(options);
      return response;
    } catch (er) {
      if (er.response) {
        //manage errors
        if (er.response.status >= 300 || er.response.status < 200) {
          try {
            let json = er.response.data;
            let errors = json.metadata?.uiMessages?.errors;
            if(errors)
            {
              errors.forEach((x) => {
                this.showError(x.title + " - " + x.message);
              }, this);
            }

            errors = json?.errors;
            if(errors)
            {
              Object.keys(errors)?.forEach((x) => {
                let error=[...errors[x]];
                this.showError("Error:"+x+" ("+error.join(',')+")");
              }, this);
            }
            return {};
          } catch (e2) {
            console.log(e2);
            this.showError("Request failed " + e2.message);
          }
        }
      }
      this.showError(er.message);
    }

    return {};
  },
  httpRequestForFileDownload: async function (url, method, body, query, _headers) 
  { 
    let options = {
      url: url,
      method: method,
      headers: _headers,
      data: body,
      params: query,
      responseType: 'blob',
    };
    return await this.httpRequestInternal(options);

  },
  httpRequest: async function (url, method, body, query, _headers) {
    let options = {
      url: url,
      method: method,
      headers: _headers,
      data: body,
      params: query,
    };
    return await this.httpRequestInternal(options);
  },
  parseDate : function (dateString) {
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    console.log(date.getTimezoneOffset());
    return formattedDate;
  },
  parseDateUTC : function (dateString) {
    if (!dateString) return null;
    const date = new Date(dateString);
    const day = date.getUTCDate();
    const month = date.getUTCMonth() + 1;
    const year = date.getUTCFullYear();
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    const formattedDate = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;
    return formattedDate;
  },
  parseDateWithMoment : function (dateString) {
    if (!dateString) return null;
    const parsedDate = moment(dateString).local();
    const formattedDate = parsedDate.format('DD/MM/YYYY HH:mm:ss');
    return formattedDate;
  },
  getFlowWebHookUrl: function(tenantId, webHookPath) {
    return this.getWebhookUrl() + "api/wh/" + tenantId + "/" + webHookPath;
  }
};

const methods={
  getFormattedDate: function (date, format) {
    return moment(date).format(format)
  },
}
Object.assign(methods,HttpClient);

Vue.mixin({
  data: function () {
    return {
      // isLoading:false,
    };
  },
  methods: methods
});

export default EventBus;
export const httpClient=Object.assign(HttpClient,{axios:axios}); 
