<template>
  <v-container>
    <v-row>
      <v-col class="d-flex justify-end">
        <v-menu bottom right style="z-index: 100">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="24"
              style="z-index: 100"
              v-bind="attrs"
              v-on="on"
              fab
              large
              fixed
              top
              right
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="newItem">
              <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>New Item</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="triggerFileInput">
              <v-list-item-icon>
                <v-icon>mdi-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Import</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <input type="file" accept=".json" ref="fileInput" @change="handleFileSelect" style="display: none;" />
      </v-col>
      <v-col cols="12">
        <v-dialog class="ips-dialog" v-model="deleting" width="400">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Confirm before delete
            </v-card-title>

            <v-card-text> Delete {{ this.editingItem.name }}? </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="deleting = false">
                Cancel
              </v-btn>
              <v-btn color="error" @click="deleteItem()">
                <v-icon>mdi-trash-can</v-icon>Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog class="ips-dialog" v-model="dialog" hide-overlay transition="dialog-bottom-transition" persistent>
          <v-card>
            <v-toolbar dark color="grey darken-4" fixed>
              <v-btn icon dark @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ editingTitle }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="undo"
                  v-if="
                    this.editingItem != null &&
                    this.editingItem.id != null &&
                    this.editingItem.id != ''
                  ">Reload</v-btn>
                <v-btn dark text @click="save"> Save </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <!-- DATA -->
            <!-- <vue-monaco-editor v-model:value="code" height="600px" language="json"/> -->
            <forms-editor v-model="editingItem" :value="editingItem" />
          </v-card>
        </v-dialog>

        <v-list-item v-for="(item, i) in destinations" :key="item.id">
          
          <v-card width="100%" class="mb-5">
            <v-list-item three-line>
              <v-list-item-content>
                <div class="ips-subtitle mb-4 upper">
                  {{ item.destinationType }}
                </div>
                <v-divider></v-divider>
                <v-list-item-title class="text-h5 mb-1">
                  {{ item.name }}
                </v-list-item-title>
                <v-list-item-subtitle>{{ item.id }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
            <v-card-actions class="py-2 ips-card-actions">
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed rounded icon color="blue-grey darken-1" v-bind="attrs" v-on="on" left>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="startDelete(item)">
                    <v-list-item-title @click="startDelete(item)">
                      <v-icon>mdi-trash-can</v-icon> Delete</v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="exportJson(item)">
                    <v-list-item-title>
                      <v-icon>mdi-file-export</v-icon>
                      Export
                      </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn rounded depressed color="blue-grey lighten-5" @click="edit(item)">Edit</v-btn>
            </v-card-actions>
          </v-card>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormsEditor from "../shared/FormsEditor.vue";
import DummyService from "../../services/DummyService";
import Vue from "vue";

export default {
  name: "Destinations",
  inject: { dummyService: new DummyService("CHILD") },
  components: {
    FormsEditor,
  },
  beforeMount: async function () {
    let response = await this.apiCall("/api/destinations/list", "POST", {}, {});
    console.log(response.data.payload.content);
    if (response.status == 200) {
      this.destinations = response.data.payload.content;
    }
  },
  methods: {
    handleFileSelect : async function(event) {
      if (event.target.files.length > 0) {
        this.jsonFile = event.target.files[0];
        await this.uploadDestination();
        this.$refs.fileInput.value = null;
      }
    },
    triggerFileInput : function() {
      this.$refs.fileInput.click();
    },
    uploadDestination: async function () {
    if (this.jsonFile) {
    const formData = new FormData();
    formData.append('file', this.jsonFile);
    var response = await this.apiCallForUpload('/api/destinations/upload',formData,{},{})}
    if (response.status === 200) {
      this.showSuccess("Destination successfully created");}
    },
    save: async function () {
      if (!this.editingItem.isValid) {
        this.showError(this.editingItem.errorMessage)
      } else {
        console.log("save", this.editingItem);
        if (this.editingItem.destinationType == "file" && this.editingItem.settings.formatSettings != null &&
            typeof this.editingItem.settings.formatSettings.mapping === "string") {
          this.editingItem.settings.formatSettings.mapping = JSON.parse(this.editingItem.settings.formatSettings.mapping);
        }
        delete this.editingItem.isValid;
        delete this.editingItem.errorMessage;
        if (
          this.editingItem == null ||
          this.editingItem.id == undefined ||
          this.editingItem.id == ""
        ) {
          await this.insert(this.editingItem);
        } else {
          await this.update(this.editingItem);
        }
        this.dialog = false;
      }
    },

    exportJson: async function (data) 
    {
      this.editingItem = data;
      return await this.apiCallForDownload(data.name,`/api/destinations/${this.editingItem.id}/download`,"GET",{},{});
    },
    insert: async function (data) {
      let response = await this.apiCall(`/api/destinations`, "POST", data, {});

      if (response.status == 200) {
        let replacement = response.data.payload.content;
        this.destinations.push(replacement);
      }
    },
    update: async function (data) {
      let response = await this.apiCall(
        `/api/destinations/${this.editingItem.id}`,
        "PUT",
        data,
        {}
      );
      if (response.status == 200) {
        let replacement = response.data.payload.content;
        let idx = this.destinations.findIndex(
          (x) => x.id == this.editingItem.id,
          this
        );
        console.log(idx);
        this.destinations.splice(idx, 1, replacement);
        //todo: udpae destinations with new object
      }
    },
    deleteItem: async function () {
      this.showSaving("Deleting Item " + this.editingItem.name);
      let response = await this.apiCall(
        `/api/destinations/${this.editingItem.id}`,
        "DELETE",
        {},
        {}
      );
      if (response.status == 200) {
        this.showSuccess("Item deleted");
        let idx = this.destinations.findIndex(
          (x) => x.id == this.editingItem.id,
          this
        );
        this.destinations.splice(idx, 1);
      } else {
        this.showError(response.data.metadata.uiMessages.errors[0].uiMessages);
      }
      this.deleting = false;
    },
    startDelete: async function (data) {
      this.editingItem = data;
      this.deleting = true;
    },
    undo: async function () {
      let response = await this.apiCall(
        `/api/destinations/${this.editingItem.id}`,
        "GET",
        {},
        {}
      );
      let data = response.data.payload.content;
      if (response.status == 200) {
        let idx = this.destinations.findIndex(
          (x) => x.id == this.editingItem.id,
          this
        );
        this.destinations[idx] = data;
        this.editingItem = this.destinations[idx];
        this.showSuccess("Item reloaded");
      } else {
        this.showError(response.data.metadata.uiMessages.errors[0].uiMessages);
      }
    },
    edit: async function (data) {
      console.log(data);
      this.editingItem = data;
      this.dialog = true;
      this.editingTitle = "Editing " + data.name;
    },
    newItem: async function (data) {
      this.editingItem = {
        destinationType: "null",
        settings: {
          traceData: true,
        },
        name: "",
      };
      this.dialog = true;
      this.editingTitle = "New destination";
    },
    reloadEgress: async function (data) {
      this.showSaving("Reloading Egress");
      let response = await this.egressCall(
        `/api/status/reload?destinationId=${data.id}`,
        "POST",
        {},
        {}
      );
      this.showSuccess("Egress reloaded");
      this.dialog = false;
    },
    close: function ()
    {
      this.dialog = false;
    }
  },
  data: () => ({
    destinations: [],
    dialog: false,
    editingItem: {},
    editingTitle: "",
    deleting: false,
    jsonFile: null
  }),
};
</script>
