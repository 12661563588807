<template>
    <v-container :key="mappingKey">
      <v-row>
        <v-col cols="3">
          <h3>Destination</h3>
        </v-col>
        <v-col cols="1">
        </v-col>
        <v-col cols="3">
          <h3>Mapper</h3>
        </v-col>
        <v-col cols="3">
          <h3>Source</h3>
        </v-col>
        <v-col cols="2">
          <v-btn @click="add" color="primary" ><v-icon
        left
        dark
      >
        mdi-plus
      </v-icon>ADD</v-btn>
        </v-col>
      </v-row>
      <v-row v-for="(map, idx) in internalValue.mappings" :key="idx">
        <v-col cols="3">
        <v-row 
      justify="center">
          <v-col cols="2"><v-checkbox v-model="map.isActive"></v-checkbox></v-col>
          <v-col cols="10"><v-combobox
            v-model="map.destination"
            :items="internalValue.outputFields"
            item-text="id"
            item-value="id"
            outlined
            :return-object="false"
            dense
            :editable="true"
            @keyup="onKeyUpDestination(idx, $event)"
          ></v-combobox></v-col>
          
        </v-row>
          
        </v-col>
        <v-col cols="1" class="justify-center text-center ">
          <v-icon>mdi-arrow-left-thin</v-icon>
        </v-col>
        <v-col cols="3">
          <v-combobox
            v-model="map.mapper"
            :items="mappers"
            item-text="title"
            item-value="id"
            outlined
            :return-object="false"
            dense

          ></v-combobox>
        </v-col>
        <v-col cols="3">
          <v-combobox
              v-model="map.source"
              :items="internalValue.inputFields"
              item-text="id"
              item-value="id"
              outlined
              :return-object="false"
              dense
              :editable="true"
              @keyup="onKeyUpSource(idx, $event)"
          ></v-combobox>
        </v-col>
        <v-col cols="2">
          <v-btn @click="remove(map,idx)" small><v-icon
        left
        dark
      >
        mdi-delete
      </v-icon>
        DELETE</v-btn>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import Vue from "vue";
import { defineComponent } from "vue";
import { ref } from 'vue';

export default defineComponent({
  name: "Mapping",
  components: {},
  props: ["value"],
  setup: function () {},
  beforeMount: async function () {
    console.log(this.value);
    this.internalValue = this.value;
      Object.assign(this.internalValue, {"mappings":this.internalValue.mappings??[]})
  },
  methods: {
    makeObject: async function(val){  
      console.log("val", val); 
      if (typeof val === "string") {
        return  {
          id: val,
        };
      }
      return val;
    },
    remove: async function(map, idx){  
      this.internalValue.mappings.splice(idx,1);
    },
    add: async function(){      
      console.log("add pressed");
      this.internalValue.mappings.push({source:"", destination:""});
      this.forceRerenderList();
    },
    change: async function () {
      this.$emit("input", this.internalValue);
    },
    forceRerenderList: function() {
      this.mappingKey += 1;
    },
    onKeyUpDestination(index, event) {
      const currentValue = event.target.value;
      this.internalValue.mappings[index].destination = currentValue;
    },
    onKeyUpSource(index, event) {
      const currentValue = event.target.value;
      this.internalValue.mappings[index].source = currentValue;
    }
  },
  watch:
  {
    value:function(newvalue, oldvalue){
      this.internalValue=newvalue;
    }

  },
  data: function () {
    return {
      internalValue: {},
      select: "",
      mappers:[
        {id:"null", title:"-"},
        {id:"toint", title:"Convert  to Int"},
        {id:"todecimal", title:"Convert to Decimal"},
        {id:"tostring", title:"Convert to String"},
        {id:"constant", title:"Constant"},
      ],
      mappingKey: 0
    };
  },
});
</script>
