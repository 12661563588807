<template>
  <v-container>
    <v-row class="text-center">
      <v-col>
        <h1>DestinationsEDIT</h1>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "DestinationsEDIT",
  beforeMount: async function () {},
  methods: {
    save: async function (data) {},
  },
  data: () => ({}),
};
</script>
