 <template v-slot:activator="{ on }">
      <v-card>
        <v-card-text class="px-0 py-0">
          <!--
          <v-tabs fixed-tabs v-model="activeTab">
            <v-tab key="calendar">
              <slot name="dateIcon">
                <v-icon>mdi-calendar</v-icon>
              </slot>
            </v-tab>
            <v-tab key="timer" :disabled="dateSelected">
              <slot name="timeIcon">
                <v-icon>mdi-timetable</v-icon>
              </slot>
            </v-tab>
            <v-tab-item key="calendar">
              <v-date-picker v-model="date" v-bind="datePickerProps" @input="showTimePicker"></v-date-picker>
            </v-tab-item>
            <v-tab-item key="timer">
              <v-time-picker
                ref="timer"
                class="v-time-picker-custom"
                v-model="time"
                v-bind="timePickerProps"
              ></v-time-picker>
            </v-tab-item>
          </v-tabs>
          -->
          <v-row no-gutters>
            <v-col min-width="290px" cols="auto">
              <v-date-picker v-model="date" v-bind="datePickerProps" @input="showTimePicker"></v-date-picker>
            </v-col>
            <v-col min-width="290px" cols="auto">
              <v-time-picker
                  ref="timer"
                  v-model="time"
                  v-bind="timePickerProps"
                  format="24hr"
              ></v-time-picker>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <slot name="actions" :parent="this">
            <v-btn color="grey lighten-1" text @click.native="clearHandler">{{ clearText }}</v-btn>
            <v-btn color="green darken-1" text @click="okHandler">{{ okText }}</v-btn>
          </slot>
        </v-card-actions>
      </v-card>
  </template>
  
  <script>
  import { format, parse } from 'date-fns'
  import moment from "moment"; 
  
  const DEFAULT_DATE = ''
  const DEFAULT_TIME = '00:00:00'
  const DEFAULT_DATE_FORMAT = 'yyyy-MM-dd'
  const DEFAULT_TIME_FORMAT = 'HH:mm:ss'
  const DEFAULT_DIALOG_WIDTH = 340
  const DEFAULT_CLEAR_TEXT = 'CLEAR'
  const DEFAULT_OK_TEXT = 'OK'
  
  export default {
    name: 'datetimepicker',
    model: {
      prop: 'datetime',
      event: 'input'
    },
    props: {
      datetime: {
        type: [Date, String],
        default: null
      },
      disabled: {
        type: Boolean
      },
      loading: {
        type: Boolean
      },
      label: {
        type: String,
        default: ''
      },
      dialogWidth: {
        type: Number,
        default: DEFAULT_DIALOG_WIDTH
      },
      dateFormat: {
        type: String,
        default: DEFAULT_DATE_FORMAT
      },
      timeFormat: {
        type: String,
        default: 'HH:mm'
      },
      clearText: {
        type: String,
        default: DEFAULT_CLEAR_TEXT
      },
      okText: {
        type: String,
        default: DEFAULT_OK_TEXT
      },
      textFieldProps: {
        type: Object
      },
      datePickerProps: {
        type: Object
      },
      timePickerProps: {
        type: Object
      }
    },
    data() {
      return {
        display: false,
        activeTab: 0,
        date: DEFAULT_DATE,
        time: DEFAULT_TIME
      }
    },
    beforeMount() {
      if (this.datetime !== null && typeof this.datetime === "string" &&  this.datetime !== "") {
        const parts = this.datetime.split(" ", 2);
        this.date = this.formatDate(this.parseDate(parts[0]));
        this.time = this.formatTime(parts[1]);
      }
      this.init()
    },
    mounted() {
      this.init()
    },
    computed: {
      dateTimeFormat() {
        return this.dateFormat + ' ' + this.timeFormat
      },
      defaultDateTimeFormat() {
        return DEFAULT_DATE_FORMAT + ' ' + DEFAULT_TIME_FORMAT
      },
      formattedDatetime() {
        return this.selectedDatetime ? format(this.selectedDatetime, this.dateTimeFormat) : ''
      },
      selectedDatetime() {
        if (this.date && this.time) {
          let datetimeString = this.date + 'T' + this.time
          if (this.time.length === 5) {
            datetimeString += ':00'
          }
          //return parse(datetimeString, this.defaultDateTimeFormat, new Date())
          return moment(datetimeString).format("DD/MM/YYYY HH:mm");
        } else {
          return null
        }
      },
      dateSelected() {
        return !this.date
      }
    },
    methods: {
      init() {
        // if (!this.datetime) {
        //   return
        // }
  
        // let initDateTime
        // if (this.datetime instanceof Date) {
        //   initDateTime = this.datetime
        // } else if (typeof this.datetime === 'string' || this.datetime instanceof String) {
        //   // see https://stackoverflow.com/a/9436948
        //   initDateTime = parse(this.datetime, this.dateTimeFormat, new Date())
        // }
  
        // this.date = format(initDateTime, DEFAULT_DATE_FORMAT)
        // this.time = format(initDateTime, DEFAULT_TIME_FORMAT)
      },
      okHandler() {
        this.resetPicker()
        this.$emit('input', this.selectedDatetime)
      },
      clearHandler() {
        this.resetPicker()
        this.date = DEFAULT_DATE
        this.time = DEFAULT_TIME
        this.$emit('input', null)
      },
      resetPicker() {
        this.display = false
        this.activeTab = 0
        if (this.$refs.timer) {
          this.$refs.timer.selectingHour = true
        }
      },
      showTimePicker() {
        this.activeTab = 1
      },
      parseDate(inputDate) {
      let [day, month, year] = inputDate.split('/').map(Number);
      return new Date(year, month - 1, day); // Mese è zero-based
    },
    formatDate(date) {
      let year = date.getFullYear();
      let month = String(date.getMonth() + 1).padStart(2, '0');
      let day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    },
    formatTime(inputTime) {
      let [hours, minutes] = inputTime.split(':').map(Number);
      return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:00`;
    }
    },
    watch: {
      datetime: function(newValue) {
        this.datetime = newValue;
        if (this.datetime !== null && typeof this.datetime === "string" &&  this.datetime !== "") {
        const parts = this.datetime.split(" ", 2);
        this.date = this.formatDate(this.parseDate(parts[0]));
        this.time = this.formatTime(parts[1]);
      }
        this.init()
      }
    }
  }
  </script>

 <style lang="scss" scoped>
 .v-picker::v-deep {
   border-radius: 0px;

   .v-picker__title {
     min-height: 102px;
   }
 }
 </style>
