<template>
  <v-container>
    <CommonEditor v-model="value">
      <template v-slot:edit="{ objVal }">
        <v-text-field v-model="objVal.name" label="Name" :rules="fieldRequired" required/>
        <v-select v-model="objVal.type" :items="types" item-text="label" item-value="id" label="Action Type" required :rules="fieldRequired">
        </v-select>
      </template>
    </CommonEditor>
  </v-container>
</template>

<script>
import Vue from "vue";
import CommonEditor from "../shared/CommonEditor";


export default {
  name: "ActionsEdit",
  components: {
    CommonEditor,
  },
  //props: ["value", "types"],
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    types: {
      type: Array,
      default: () => [
        //{id:"cSharp", label:"C# Code", color:"primary"},
        {id:"mapping", label:"Field mapping", color:"secondary"}
        //{id:"template", label:"Template", color:"green"}
      ],
    }
  },
  setup: function () {},
  beforeMount: async function () {},
  data: function () {
    return {
      fieldRequired: [
        v => !!v || 'Field is required',
      ]
    }
  },
  methods: {

  }
};
</script>
