import { jwtDecode } from "jwt-decode";
class UserService {
  constructor() {}

  get user() {
    return JSON.parse(localStorage.getItem("user"));
  }
  isLoggedIn() {
    let token = this.user != null;
  }
  remove() {
    localStorage.removeItem("user");
  }
  setTenant(tenant) {
    var user = this.user;
    user.tenant = tenant;
    console.log("switching tenant", this.user, tenant);
    localStorage.setItem("user", JSON.stringify(user));
  }
  setUser(user) {
    var activeuser = this.user;

    if (user.loginType == "Oauth") {
      user.tenants = [];
      user.tenant = "";
      const decoded = jwtDecode(user.token);
      user.hasBetaFeatures=false;
      if(decoded.hasOwnProperty("https://sintraoms.com/ip-beta") && decoded["https://sintraoms.com/ip-beta"]==true)
      {
        user.hasBetaFeatures=true;
      }
      
      var tenantList = decoded["https://sintraoms.com/tenants"].split(",");

      tenantList.forEach((x) => {
        user.tenants.push({
          name: x,
          description: x + " description",
        });
      });
      var defaultTenant = decoded["https://sintraoms.com/tenant"];
      if (activeuser) {
        if (
          tenantList.findIndex((element) => element == activeuser.tenant) > 0
        ) {
          user.tenant = activeuser.tenant;
        }
      } else {
        user.tenant = defaultTenant;
      }
    } else if (user.loginType == "ApiKey") {
      // todo: manage by user service...
      user.picture = "https://i1.wp.com/cdn.auth0.com/avatars/ak.png?ssl=1";
      user.nickname = "Apikey User";
      user.email = "";
      user.hasBetaFeatures=false;
      //user.tenant supposed to be set from outside
      //user.tenants supposed to be set from outside
    }
    localStorage.setItem("user", JSON.stringify(user));
  }
}

export default new UserService();
