<template>
  <v-container>
    <v-overlay opacity="1" light color="#fafafa" flex>
      <v-card elevation="1" light width="600px" center height="600px">
        <h1 class="text-center pt-10 mb-2 h2 fw-normal">Hello</h1>
        <v-card-text center class="text-center mt-5">
          <v-row>
            <v-col>
              <v-spacer></v-spacer>
              <!-- show login when not authenticated -->
              <v-btn @click="loginOauth" center primary x-large depressed color="primary"
                >Log in with Oauth</v-btn
              >
            </v-col>
          </v-row>
          <v-row> <v-col>OR</v-col></v-row>
          <v-row>
            <v-col>
              <v-card class="mt-5" elevation="6">
                <v-card-title>Login with api Key</v-card-title>
                <v-card-text>
                  <v-text-field
                    v-model="apiKeyToken"
                    type="password"
                    label="Token"
                    id="password"
                  />
                  <v-text-field
                    v-model="apiKeyTenant"
                    type="text"
                    label="Tenant"
                    id="tenant"
                  />
                  <v-btn @click="loginApi" depressed outlined color="primary" center primary x-large
                    >Login with API Key</v-btn
                  >
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-overlay>
  </v-container>
</template>
<script>
import auth0client from "../../services/auth0client";
import userService from "../../services/user";

export default {
  data: function () {
    return {
      domain: "",
      redirectUri: "",
      clientId: "",
      apiKeyToken: "",
      apiKeyTenant: "",
    };
  },
  created: function () {
    this.domain = process.env.VUE_APP_DOMAIN;
    this.client_id = process.env.VUE_APP_CLIENT_ID;
    this.redirect_uri = process.env.VUE_APP_REDIRECT_URI;
  },
  methods: {
    // Log the user in
    async loginOauth() {
      let loginClient = await auth0client;
      await loginClient.loginWithRedirect();
    },
    loginApi() {
      var newUser = {
        loginType: "ApiKey",
        token: this.apiKeyToken,
        tenant: this.apiKeyTenant,
        tenants: [
          {
            name: this.apiKeyTenant,
            description: this.apiKeyTenant,
          },
        ],
        isAuthenticated: true,
      };
      console.log("loginApiKey", newUser);
      userService.setUser(newUser);
      this.send("user:loggedin", newUser);
      this.$router.push({ name: "settings" }).catch(() => {});
    },
  },
};
</script>

