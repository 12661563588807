class ObjectStorage {
    constructor() {
    }
    async save(object, key,value) 
    {
        localStorage.setItem(object+"_"+key,JSON.stringify(value));
    }
  
    async get(object, key) {
       let str= localStorage.getItem(object+"_"+key);
       return JSON.parse(str);
    }
  }

  export default new ObjectStorage();
  