<template>
  <v-tabs v-model="activeTab" @change="tabChange">
    <v-tab :key="0">Editor</v-tab>
    <v-tab :key="1">Raw data</v-tab>

    <v-tab-item :key="0">
      <slot name="edit" v-bind:objVal="objVal"></slot>
    </v-tab-item>
    <v-tab-item :key="1">
      <vue-monaco-editor v-model="code" height="600px" language="json" loading="false"/>
    </v-tab-item>
  </v-tabs>
</template>

<script>
import Vue from "vue";

export default {
  name: "CommonEditor",
  components: {},
  props: ["value"],
  setup: function () {},
  beforeMount: async function () {
    await this.setValue(this.value);
  },
  methods: {
    tabChange: async function () {
      console.log(this.activeTab);
      if (this.activeTab === 0 || this.activeTab === 1) {
        Object.assign(this.objVal, JSON.parse(this.code));
      }
    },
    setValue: async function (newValue) {
      this.code = JSON.stringify(newValue, null, 2);
      this.objVal = newValue;
    },
  },
  data: function () {
    return {
      inited: false,
      code: "",
      action: {},
      activeTab: 0,
      objVal: {},
    };
  },
  watch: {
    value: {
      handler: async function (newValue, oldValue) {
        console.log(oldValue, newValue);
        this.inited = false;
        await this.setValue(newValue);
      },
      once: true,
      immediate: true,
    },
    // code: function (oldvalue, newValue)
    // {

    // }
    objVal: {
      handler: function (newValue, oldValue) {
        this.code = JSON.stringify(newValue, null, 2);
        console.log("objVal changed", oldValue, newValue, this.value);
        if (!this.value === newValue) {
          console.log("emit change");
          this.$emit("input", this.objData);
        }
      },
      deep: true,
      once: true,
      immediate: true,
    },
  },
};
</script>
