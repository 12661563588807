<template>
  <Bar
      id="my-chart-id"
      :options="chartOptions"
      :data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :style="myStyles"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 100
    },
    height: {
      type: Number,
      default: 70
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {
        height: "150px"
      }
    },
    plugins: {
      type: Array,
      default: () => []
    },
    test: {
      type: Object,
      default: () => {}
    },
    chartData: {
      type: Object,
      default: () => {},
      required: true 
    },
    eventOnClickEmit: {
      default: '',
      type: String
    }
  },
  beforeMount: async function () {
    //console.log("Before Mount");
  },
  data() {
    return {
      chartOptions: {
        onHover: (event, chartElement) => {
          if (chartElement.length) {
            event.native.target.style.cursor = 'pointer';
          } else {
            event.native.target.style.cursor = 'default';
          }
        },
        responsive: true,
        maintainAspectRatio: true,
        onClick: (evt, items) => {
          if (items && items.length > 0) {
            const clickedBarIndex = items[0].datasetIndex;
            const clickedBarLabel = this.chartData.labels[items[0].index];
            const clickedBarValue = this.chartData.datasets[clickedBarIndex].data[items[0].index];
            const clickedLegend = this.chartData.datasets[clickedBarIndex].label;
            this.$emit(this.eventOnClickEmit, { label: clickedBarLabel,legend: clickedLegend });
          }
        }
      }
    }
  },
  computed: {
    myStyles () {
      return {
        height: `${this.height}px`,
        width: '100px'
      }
    },
    onClick: function(evt) {
      console.log(evt);
    }
  }
}
</script>
