<template>
  <v-container class="ips-flex">    
    <v-row class="ips-row">
      <v-col v-for="(flow, i) in flows" :key="i" class="ips-col">
        <v-hover v-slot="{ hover }">
          <v-card
            class="ips-card"
            min-width="500"
            :elevation="hover ? 12 : 2"
             @click="view(flow.id)"
          >
             <v-card-title >{{ flow.name }}</v-card-title>
             <v-card-subtitle><b>Flow-Id:</b> {{ flow.id }} </v-card-subtitle>
            <v-divider></v-divider>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
</template>

<style lang="sass" scoped>
.v-card.on-hover
  background-color: rgba(#FFF, 0.8)
  >.v-card__text
    color: #000
</style>

<script>
export default {
  name: "Dashboard",
    beforeMount: async function () {
    let response = await this.apiCall(
      "/api/editableflows/list",
      "POST",
      {
        "orderBy": [
    {
      "name": "name",
      "direction": "asc"
    }]
      },
      {}
    );
    console.log(response.data.payload.content);
    if (response.status == 200) {
      let flows = response.data.payload.content;
      this.flows = flows;
    }
  },
  data: () => ({
    flows: {},
    dynamicFields: [],
    headers: [],
  }),
  methods: {
    view: async function (item) {
      this.$router
        .push({ name: "flows.view", params: { id: item } })
        .catch(() => {});
    },
  },
};
</script>
