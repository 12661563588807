<template>
  <ListView labelEdit="Action" title="Applications" >
    <template v-slot:itemTitle="{ item }">
      <!--
      <v-btn @click="view(item)" class="text-h5" style="color: black" plain>
        {{ item.name }}
      </v-btn>
      -->
      <a @click="view(item)" class="text-h5 py-2 ips-link" style="color: black" plain>
        {{ item.name }}
      </a>
    </template>
    <template v-slot:itemSubTitle="{ item }">
      <v-chip class="ma-2" :color="types.find(x=>x.id === item.type)?.color">
        {{item.type }}
      </v-chip>
    </template>
    <template v-slot:itemContent="{ item }">
     
    </template>
    <template v-slot:edit="{ editingItem }">
      <ActionEdit :value="editingItem" :types="types" />
    </template>
  </ListView>
</template>

<script>
import Vue from "vue";
import ActionEdit from "../lowcode/ActionEdit";
import ListView from "../shared/ListView";
import restDataService from '../../extensions/restDataService';

ListView = Vue.extend(ListView).extend(restDataService("action"));

export default {
  name: "Actions",
  components: {
    ListView,
    ActionEdit,
  },
  setup: function () {},
  methods: {
    view: async function (item) {
      this.$router
        .push({
          name: "actions.design",
          params: { id: item.id, title: "Design Action - " + item.name },
          meta: { title: "mycustom" },
        })
        .catch(() => {});
    },
  },
  data: function () {
    return {
      types:[
        //{id:"cSharpCode", label:"C# Code", color:"primary"},
        {id:"mapping", label:"Field mapping", color:"yellow"}
        //{id:"template", label:"Template", color:"green"},
      ]
    };
  },
}
</script>
