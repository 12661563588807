<template>
<v-card flat  >
    <v-card-title> <v-toolbar flat ><v-toolbar-title>{{title}}</v-toolbar-title>       <v-spacer></v-spacer>
        <slot
          name="toolbar-elements"
          
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on: onTooltip }">
              <v-btn
                fab
                text
                elevation="0"
                small
                v-on="onTooltip"
                @click="addItem" 
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          Add
          </v-tooltip>
        </slot></v-toolbar>
    </v-card-title>
      <v-card-text>
            <v-row v-for="key in value" v-bind:key="key.index">
                <v-col cols="5" ><v-text-field  v-model="key.key"   @change="onChange()"/></v-col>
                <v-col cols="5" ><v-text-field v-model="key.value"    @change="onChange()"/> </v-col>
                <v-col cols="2">  <v-icon @click="deleteItem(key)">mdi-delete</v-icon></v-col>

            </v-row>
    </v-card-text>
    </v-card>
  </template>
  
  <script>
  // Importa il renderer di array da JSON Forms
  import { ArrayControlRenderer,ArrayLayoutRenderer, useVuetifyControl } from '@jsonforms/vue2-vuetify';
  import { ControlElement, JsonFormsRendererRegistryEntry, rankWith, isStringControl } from '@jsonforms/core'

import { defineComponent, ref, unref } from 'vue';

import {
  useJsonFormsControl,
  useJsonFormsArrayControl,
  RendererProps,
  useJsonFormsRenderer,
  ControlProps,
  useJsonFormsControlWithDetail
} from '@jsonforms/vue2';
  
  export default defineComponent( {
    name: 'MapRender',
    beforeMount:async function(){
        this.value=this.objectToArray(this.control.data??{});
        this.title=this.uischema.label;
        //console.log(this); 
    },
    
    setup (props) {
        // console.log("marrender propd",props);
        // return useJsonFormsControlWithDetail(props)
        return useVuetifyControl(
      useJsonFormsControl(props),
      (newValue) => newValue || false
    );
    },
    data: () => ({
            title:"",
            value: [],
           
    }),
    props: [ 'data','cells', 'uischema', 'schema', 'path', 'enabled','rootSchema','config','id'],
    methods: {
      arrayToObject: (array)=>{
        var obj={};
        if(array)
        {
            array.forEach(x=>{
                obj[x.key]=x.value;
            });
        }
        return obj;
      },
      objectToArray: (obj)=>{
        var keys=Object.keys(obj);
        var items=[]
        if(keys)
        {
            let i=0;
        keys.forEach(x=>{
            items.push({
                index:i++,
                key:x,
                value:obj[x]
            });
        });
    }
        return items;
      },
      computeFake: function(paths,value, result)
      {
        if(paths.length==1){ 
            result[paths[0]]=value;
            return;
        }
        const curPath=paths[0];
        paths.shift();
        result[curPath]={};
        this.computeFake(paths,value,result[curPath]);
      },
      deleteItem(item){
        var id=this.value.findIndex((x)=>x.key==item.key);
        this.value.splice(id,1);
        this.onChange();
      },
      addItem() {
        var empties=this.value.filter((item) => item==null || item.key.trim().length ==0);

        if( empties.length>0) return;
        this.value.push(this.createDefaultItem());
        this.onChange();
        //this.handleChange("dfdfg",{"dfs":"sdfsf"})
      },
      createDefaultItem() {
        return { "index": this.value.length.toString(),
        "key":"",
        "value":""}
      },
      
      onChange(event) {
         var data=this.arrayToObject(this.value);
         var path=this.uischema.scope.replace("#\/","").replaceAll("properties/","").replaceAll("\/",".");
         if (this.path === "")
         {
           this.handleChange(path,data);
         }
         else
         {
           this.handleChange(this.path + "." + path,data);
         }

      }
        
    //   },
    //   emitChange() {
    //     this.$emit('value', "dsfsf");
    //     this.$emit('input', "etere");
    //   }
    },
    
     watch: {
        value: function(oldvalue,newValue){
           // console.log("value.changed",oldvalue,newValue);
        },
        "control.data": function(oldvalue,newValue){
            //console.log("controldata.changed",oldvalue,newValue);
            this.value=this.objectToArray(this.control.data??{});
        },
        
        "data": function(oldvalue,newValue){
           // console.log("data.changed",oldvalue,newValue);
        }
     },
    computed:{
    //     id() {
    //   return this.path;
    // },
        schemaFake: function () {
            const paths = this.uischema.scope.replace(/^#/, '').split('/').filter(p => p !== '');
           // console.log(paths);
            var obj = {};
           this.computeFake(paths,this.defaultSchema, obj)
            return obj;
        }
      }
    
  });
  </script>
  
  <style scoped>
  .custom-array-layout {
    /* Stili personalizzati per il tuo componente */
  }
  </style>
