<template>
  <v-container class="vw-max-750">
    <h1 class="mb-2 fw-normal h2">Users</h1>
    <v-card>
      <v-tabs grow v-model="activeTab" @change="tabChange">
        <v-tab :key="0">User</v-tab>
        <v-tab :key="1">Raw</v-tab>
        <v-tab :key="2">Token</v-tab>
        <v-tab-item :key="0">
          <json-forms
            :data="user"
            :schema="schema"
            :uischema="uischema"
            :renderers="renderers"
          />
        </v-tab-item>
        <v-tab-item :key="1">
          <div class="d-block pa-4 text-right"><v-icon @click="reload">mdi-reload</v-icon></div>
          
          <vue-monaco-editor
            class="pa-4 ips-border ips-background"
            v-model="userTxt"
            height="600px"
            language="json"
            loading="false"
          />
        </v-tab-item>
        <v-tab-item :key="2">
          <vue-monaco-editor
            class="pa-4 ips-border ips-background"
            v-model="tokenDecoded"
            height="600px"
            language="json"
            loading="false"
          />
        </v-tab-item>
      </v-tabs>
    </v-card>
  </v-container>
</template>
<script>
import schemas from "../../services/schemas";
import userService from "../../services/user";
import { vuetifyRenderers } from "@jsonforms/vue2-vuetify";
import { JsonForms } from "@jsonforms/vue2";
import { jwtDecode } from "jwt-decode";
export default {
  data: function () {
    return {
      isAuthenticated: false,
      user: {},
      userTxt: "",
      tokenDecoded: "",
      schema: {},
      uischema: {},
      renderers: [],
    };
  },
  components: {
    JsonForms,
  },
  created: async function () {
    this.reload();
    this.listen("user:loggedin", this, function (sender, data) {
      this.reload();
    });

    this.schema = schemas.getSchema("user");
    this.uischema = schemas.getSchemaUi("user");
    this.renderers = vuetifyRenderers;
  },
  methods: {
    reload: async function () {
      this.user = userService.user;
      this.userTxt = JSON.stringify(this.user, null, 2);
      if (this.user.loginType == "Oauth") {
        const decoded = jwtDecode(this.user.token);
        this.tokenDecoded = JSON.stringify(decoded, null, 2);
      } else {
        this.tokenDecoded = "Not a JWT user";
      }
    },
  },
};
</script>
