<template>
   
  <v-tabs>
   
    <v-tab>Input</v-tab>
    <v-tab>Output</v-tab>
    <v-tab-item>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-toolbar flat color="blue lighten-5">
                <v-toolbar-title>Input Sample</v-toolbar-title> <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn small @click="importInput">Import</v-btn>
              </v-toolbar>

              <vue-monaco-editor
                v-model="internalValue.inputSample"
                height="300px"
                language="json"
                loading="false"
              />
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-alert                
                outlined
                type="error"
                v-if="inputErrorMessage!=null && inputErrorMessage.length>0"
              >
                {{inputErrorMessage}}
              </v-alert>
            <v-data-table
              height="300px"
              :hide-default-footer="true"
              :headers="inputMappingHeaders"
              :items="internalValue.inputFields"
              dense
              item-key="id"
            ></v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>
    <v-tab-item>
      <v-container>
        <v-row>
          <v-col cols="6">
            <v-card>
              <v-toolbar flat color="blue lighten-5">
                <v-toolbar-title>Output Sample </v-toolbar-title> <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-btn small @click="importOutput">Import</v-btn>
              </v-toolbar>

              <vue-monaco-editor
                v-model="internalValue.outputSample"
                height="300px"
                language="json"
                loading="false"
              />
            </v-card>
          </v-col>
          <v-col cols="6">
            <v-alert                
                outlined
                type="error"
                v-if="outputErrorMessage!=null && outputErrorMessage.length>0"
              >
                {{outputErrorMessage}}
              </v-alert>
            <v-data-table
              height="300px"
              :hide-default-footer="true"
              :headers="outputMappingHeaders"
              :items="internalValue.outputFields"
              dense
              item-key="id"
            ></v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-tab-item>   
  </v-tabs>
</template>

<script>
import Vue from "vue";
import { flatten } from "flat";
import { defineComponent } from "vue";
export default defineComponent({
  name: "MappingSettings",
  components: {},
  props: ["value"],
  setup: function () {},
  beforeMount: async function () {
    var defaultSettings={
      inputSample:"",
      inputFields:[],
      outputSample:"",
      outputFields:[]
    };
    this.internalValue = Object.assign(defaultSettings, this.value);
  },
  methods: {
    change: async function () {
      this.$emit("input", this.internalValue);
    },
    importInput: async function () {
     let result=await this.processJSON(this.value.inputSample);
     Object.assign(this.internalValue, {inputFields:result.list});    
     this.inputErrorMessage=result.message;

    },
    importOutput: async function () {
     let result=await this.processJSON(this.value.outputSample);
     Object.assign(this.internalValue, {outputFields:result.list});    
     this.outputErrorMessage=result.message;
     
    },
    processJSON: async function (inputStr) {
      let result={
        list:[],
        message:""
      };
      try
      {
        
        let obj = JSON.parse(inputStr??"{}");
        let flat = flatten(obj);
        console.log(flat);
        let properties = Object.getOwnPropertyNames(flat);

        
        properties.forEach((x) => {
          if (x.includes(".0")) {
            result.list.push({ id: x.replaceAll(".0","[]") });
          }
          result.list.push({ id: x });
        });

      }
      catch(exc)
      {
        console.log(inputStr);
        result.message=exc.message;
      }
      return result;
    }
  },
  watch:{
    internalValue: function(newvalue, oldvalue){
      this.change();
    },
      value:function(newvalue, oldvalue){
        this.internalValue=newvalue;
      }
  },
  data: function () {
    return {
      internalValue: {},
      inputSample:"",
      inputMappingHeaders: [
        { text: "id", align: "start", sortable: false, value: "id" },
      ],
      inputFields: [],
      inputErrorMessage:"",

      outputErrorMessage:"",
      outputSample:"",
      outputMappingHeaders: [
        { text: "id", align: "start", sortable: false, value: "id" },
      ],
      outputFields: [],
    };
  },
});
</script>
