<template>
       <h1> charp map settings</h1>
      
</template>

<script>
import Vue from "vue";
import {defineComponent} from "vue"
export default defineComponent({
  name: "csharpCodeSettings",
  components: {
  },
  props: ["value"],
  setup: function () {},
  beforeMount: async function () {
    this.internalValue=this.value;
    
  },
  methods: {
    change: async function(){
        this.$emit("input", this.internalValue);
    }
  },
  data: function () {
    return {
        internalValue:{}
    };
  },
});
</script>
