import "./registerServiceWorker";
import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";

import configAxios from "./plugins/axios";
import router from "./plugins/router";
import EventBus from "./plugins/extension";
import { install as VueMonacoEditorPlugin } from "@guolao/vue-monaco-editor";
import DummyService from "./services/DummyService";
import SchemasService from "./services/schemas";

configAxios();

const app = new Vue({
  vuetify,
  router,
  provide: {
    dummyService: new DummyService("PARENT"),
    schemaService: SchemasService,
  },
  render: (h) => h(App),
});

Vue.use(VueMonacoEditorPlugin);
app.$mount("#app");
