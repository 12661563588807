<template>
  <v-container>
    <v-row>
      <v-btn
        elevation="24"
        style="z-index: 100"
        @click="newItem()"
        fab
        large
        fixed
        top
        right
      >
        <v-icon>mdi-plus</v-icon></v-btn
      >
      <v-col cols="12">
        <v-dialog v-model="deleting">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Confirm before delete
            </v-card-title>

            <v-card-text> Delete {{ this.editingItem.name }}? </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="deleting = false">
                Cancel
              </v-btn>

              <v-btn color="error" @click="deleteItem()">
                <v-icon>mdi-trash-can</v-icon>Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog
          class="ips-dialog"
          v-model="dialog"
          hide-overlay
        >
          <v-card>
            <v-toolbar dark color="grey darken-4" fixed>
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ editingTitle }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  dark
                  text
                  @click="undo"
                  v-if="
                    this.editingItem != null &&
                    this.editingItem.id != null &&
                    this.editingItem.id != ''
                  "
                >
                  Reload
                </v-btn>
                <v-btn dark text @click="save"> Save </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-form ref="form" >
            <slot name="edit" v-bind:editingItem="editingItem"></slot>
            </v-form>
          </v-card>
        </v-dialog>

        <!--<h1>{{ title }}</h1>-->
        <v-list-item v-for="(item, i) in items" :key="item.id">
          <v-card width="100%" class="mb-8">
            <v-list-item three-line>
              <v-list-item-content>
                <v-list-item-title class="text-h5 mb-2 mt-2">
                  <slot name="itemTitle" v-bind:item="item"></slot>
                </v-list-item-title>
                <v-list-item-subtitle
                  ><slot name="itemSubTitle" v-bind:item="item"></slot
                ></v-list-item-subtitle>
                <slot name="itemContent" v-bind:item="item"></slot>
              </v-list-item-content>
            </v-list-item>

            <v-card-actions class="py-2 ips-card-actions">
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on" left>
                    <v-icon color="black">mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item @click="startDelete(item)">
                    <v-list-item-title @click="startDelete(item)">
                      <v-icon>mdi-trash-can</v-icon> Delete</v-list-item-title
                    >
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn outlined rounded text @click="edit(item)"> Edit </v-btn>
            </v-card-actions>
          </v-card>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";

export default {
  name: "ListView",
  components: {},
  props: ["title", "labelEdit"],
  beforeMount: async function () {
    console.log("beforeMount");
    this.items = await this.getData();
    console.log(this.items);
  },
  methods: {
    getData: async function () {
      console.log("get data base");
      return [];
    },
    getItem: async function (itemId) {
      return {};
    },
    getEmptyItem: async function (itemId) {
      return {};
    },
    async validate() {
      return await this.$refs.form.validate();
    },
    save: async function () {
      console.log("save", this.editingItem);
      //let validated = await this.validate();
      //if (validated) {
        if (this.editingItem == null ||
            this.editingItem.id == undefined ||
            this.editingItem.id == "") {
          await this.insert(this.editingItem);
        }
        else {
          await this.update(this.editingItem);
        }
      //}
      this.dialog = false;
    },
    insert: async function (data) {
      this.showError("insert not implemented");
    },

    update: async function (data) {
      this.showError("update not implemented");
    },
    deleteItem: async function () {
      this.showError("delete not implemented");
    },
    startDelete: async function (data) {
      this.deleteItem(data);
      this.deleting = true;
    },
    undo: async function () {
      let data = this.getItem(this.editingItem.id);
      if (data) {
        let idx = this.destinations.findIndex(
          (x) => x.id == this.editingItem.id,
          this
        );
        this.destinations[idx] = data;
        this.editingItem = this.destinations[idx];
        this.showSuccess("Item reloaded");
      } else {
        this.showError(response.data.metadata.uiMessages.errors[0].uiMessages);
      }
    },
    edit: async function (data) {
      console.log(data);
      this.editingItem = data;
      this.dialog = true;
      this.editingTitle = this.labelEdit + ": Editing " + data.name;
    },
    newItem: async function (data) {
      this.editingItem = await this.getEmptyItem();
      this.dialog = true;
      this.editingTitle = "New " + this.labelEdit;
    },
  },
  data: () => ({
    items: [],
    dialog: false,
    editingItem: {},
    editingTitle: "",
    deleting: false
  }),
};
</script>
