<template>
<v-row>
    <v-col v-for="(key, index) in keys" :key="index">
       <div class="ips-subtitle">{{map[key]??key}}</div>{{data[key]}}
    </v-col>
</v-row>
</template>

<script>


export default {
    name: 'PropertyView',
    props: {
            data: {
                type: Object,
                value: {}
            },
            map: {
                type: Object,
                value: {}
            },
            exclude: {
                type: Array,
                value: []
            },
        },
        

    async created(){
       
    },
    async mounted(){      
        
       
    },
    computed:{
        keys:  function(){
            let items= Object.keys(this.data);
            return items.filter(item => !this.exclude.includes(item), this);
        }

    },
    methods:{
      
    },
    data: () => ({

    }),
  }
   </script>
   
</script>
