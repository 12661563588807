<template>

  <v-container>
    <label>{{title}}</label>
    <vue-monaco-editor
        v-model="code" class="editor" :width="size" :language="editorLanguage" :options="editorOptions" height="600px" ref="containerRef" @change="onChange"/>
  </v-container>
</template>
  
  <script>
  // Importa il renderer di array da JSON Forms
  import { useVuetifyControl } from '@jsonforms/vue2-vuetify';

import { defineComponent} from 'vue';

import {
  useJsonFormsControl,
} from '@jsonforms/vue2';
  
  export default defineComponent( {
    name: 'MonacoRender',
    beforeMount:async function(){
      console.log("monaco.iinited",this.control.data);
      this.editorLanguage = this.control.schema.options.language;
      this.title=this.uischema.label;
      let data = this.control.data??"";

      if (typeof data === 'object' && data !== null && !Array.isArray(data))
      {
        this.code=JSON.stringify(data, null, 2);
      }
      else
      {
        this.code=data;
      }

      this.$forceUpdate();  // Forza il ricalcolo del componente
      //window.addEventListener('resize', this.handleResize);
      //TODO: marco: qui capisci come passare proprietà di customizzazoine
      //https://github.com/eclipsesource/jsonforms-vuetify-renderers/blob/main/vue2-vuetify/src/layouts/CategorizationRenderer.vue

      //TODO: marco: gli stili del from non sono fissi, per cui l'autowidht dell'editor non funzoina (il div padre è un 1px perchè si adatta al contenuto.)
      //teoria: modificare le proprietà nell uischema per trasforamre i padri in blocchi con 100% width; pratica: non funziona
      
    },
    mounted:async function(){
      //this.size=this.$parent.$parent.$parent.$parent.$el.clientWidth;

    },
    setup (props) {
        return useVuetifyControl(
      useJsonFormsControl(props),
      (newValue) => newValue || false
    );
    },
    data: () => ({
      editorLanguage: "sql",
      title: "",
      code:"",
      size:900,
      editorOptions: {
        automaticLayout:true
      }
    }),
    // watch: {       
    //     "control.data": function(oldvalue,newValue){
    //         console.log("monaco.controldata.changed",oldvalue,newValue);
    //         this.value=newValue;
    //     },
    // },
    props: [ 'data','cells', 'uischema', 'schema', 'path', 'enabled','rootSchema','config','id', 'language'],
    methods: {
      // handleResize:function(){
      //   this.size=this.$parent.$parent.$parent.$parent.$el.clientWidth;
      //   console.log("resized",this.$parent.$parent.$parent.$parent.$el.clientWidth);
      // },
      // onChange:function(event){
      //   console.log("monaco.changed",event);
      //   var path=this.uischema.scope.replace("#\/","").replaceAll("properties/","").replaceAll("\/",".");
      //    this.handleChange(path,event);
      // }
    }        
   
    });
  </script>
