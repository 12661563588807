<template>
  <v-autocomplete :items="items"  v-model="selected"  :return-object="false" @update:modelValue="change" />
 </template>
   
   <script>
   // Importa il renderer di array da JSON Forms
   import { useVuetifyControl } from '@jsonforms/vue2-vuetify';
 
 import { defineComponent} from 'vue';
 import {getElementByScope} from '../../../services/uiFormUtils';
 import comboboxService from '../../../services/comboboxService';
 import {
   useJsonFormsControl,
 } from '@jsonforms/vue2';
   
   export default defineComponent( {
     name: 'ApiSelectRender',
     beforeMount:async function()
     {
       this.selected = this.control.data??"";
       //console.log("ITEM",this.schema,this.path);
       this.options=getElementByScope(this.schema,this.uischema.scope)?.options?.apiselect;
       //console.log("ITEM",xxx);
       this.items=await comboboxService[this.options.query](this.options.params);
     },
     mounted:async function(){
     },
     
     setup (props) {
      console.log(props);
         return useVuetifyControl(
       useJsonFormsControl(props),
       (newValue) => newValue || false
     );
     },
     data: () => ({
        options:{},
        items:[],
     selected:""
     }),
     props: [ 'data','cells', 'uischema', 'schema', 'path', 'enabled','rootSchema','config','id', 'language'],
     watch:{
      selected: async function(newval, oldval){
        await this.change(newval);
      }
     },
     methods: {
      change:async function(){
        
        var path=this.uischema.scope.replace("#\/","").replaceAll("properties/","").replaceAll("\/",".");
        // console.log("changing apirender", this.uischema.scope, path);
        // console.log('data',this.data);
        // console.log('cells', this.cells);
        // console.log( 'uischema', this.uischema);
        // console.log( 'schema',this.schema);
        // console.log( 'path', this.path,path );
        // console.log( 'enabled', this.enabled);
        // console.log('rootSchema', this.rootSchema);
        // console.log('config', this.config);
        // console.log('id', this.id)
        // console.log( 'language',this.language);
         this.handleChange(this.path+"."+path,this.selected);//TODO: Marco, this should work everywhere, adapt others
     },
     },
    

     });
   </script>
 