<template></template>
<script>
import auth0client from "../../services/auth0client";
import userService from "../../services/user";
export default {
  data: function () {
    return {};
  },
  created: async function () {
    this.send("app:loading", true);

    let loginClient = await auth0client;

    try {
      if (
        window.location.search.includes("code=") &&
        window.location.search.includes("state=")
      ) {
        // handle the redirect and retrieve tokens
        const { appState } = await loginClient.handleRedirectCallback();
        this.isAuthenticated = await loginClient.isAuthenticated();
        this.user = await loginClient.getUser();
        if (this.isAuthenticated) {
          //set user to session
          this.user.loginType = "Oauth";
          this.user.isAuthenticated = true;
          let token = await loginClient.getTokenSilently();
          this.user.token = token;
          userService.setUser(this.user);
          this.send("user:loggedin", this.user);
          const redirect = localStorage.getItem('redirectUrl');
          if (redirect) 
          {
            localStorage.removeItem('redirectUrl')
            this.$router.push(redirect);
          } else 
          {
            this.$router.push({ name: "dashboard" });
          }
        } else {
          console.log("user not logged in, redirect to login");
          this.$router.push({ name: "login" });
        }
      } else {
        this.showError("Error with login parameters");
        this.$router.push({ name: "login" });
      }
    } catch (e) {
      this.showError("Error during login processing");
      console.log(e);
      this.$router.push({ name: "login" });
    } finally {
    }
  },
  unmounted: function () {
    //this.send("app:loading",false);
  },
  methods: {},
};
</script>
