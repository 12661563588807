<template>
  <v-tabs v-model="activeTab">
    <v-tab :key="0">Status</v-tab>
    <v-tab :key="1">Message</v-tab>
    <v-tab :key="2">Raw data</v-tab>
    <v-tab-item :key="0">
      <json-forms
        :data="value"
        :schema="schema"
        :uischema="uischema"
        @change="onChange"
        :renderers="renderers"
      />
    </v-tab-item>
    <v-tab-item :key="1">
      <vue-monaco-editor
        v-model="message"
        height="600px"
        language="json"
        loading="false"
      />
    </v-tab-item>
    <v-tab-item :key="2">
      <vue-monaco-editor
        v-model="code"
        height="600px"
        language="json"
        loading="false"
        aria-readonly="true"
        :options="fullOpts"
      />
    </v-tab-item>
  </v-tabs>
</template>
<style>
.v-input--is-disabled .mdi-close {
  display: none !important;
}
</style>


<script>
import { vuetifyRenderers } from "@jsonforms/vue2-vuetify";
import { JsonForms } from "@jsonforms/vue2";
import schemas from "../../services/schemas";
import { readonly } from "vue";
export default {
  props: ["value"],
  components: {
    JsonForms,
  },
  beforeMount: function () {
    console.log(schemas.getSchema("message"));
    console.log(schemas.getSchemaUi("message"));
    this.schema = schemas.getSchema("message");
    this.uischema = schemas.getSchemaUi("message");
    this.renderers = vuetifyRenderers;
    this.code = JSON.stringify(this.value, null, 2);
    this.message = JSON.stringify(
      JSON.parse(this.value.messageData.msg),
      null,
      2
    );
  },
  setup(props) {},
  watch: {
    value: {
      handler(newValue, oldvalue) {
        console.log(newValue);
        this.code = JSON.stringify(newValue, null, 2);
        this.message = JSON.stringify(
          JSON.parse(this.value.messageData.msg),
          null,
          2
        );
      },
      deep: true,
    },
    code: {
      handler(newCode, oldCode) {
        if (newCode !== oldCode) {
        }
      },
      deep: true,
    },
    message: {
      handler(newMessage, oldMessage) {
        if (oldMessage !== newMessage) {
          this.value.messageData.msg = newMessage;
        }
      },
      deep: true,
    },
  },
  methods: {
    onChange(data) {
      this.value.previousStatus = this.value.status;
      this.value.status = data.data.status;
    },
  },
  data: () => ({
    fullOpts: {
      readOnly: true,
    },
    schema: {},
    uischema: {},
    renderers: {},
    code: {},
    message: {},
  }),
};
</script>
