class DummyService {
  constructor(input) {
    this.input = input;
  }
  async save(model) {
    console.log(this.input);
  }

  async save2(model) {
    console.log(this.input);
  }
}

export default DummyService;
