<template>
  <v-container>

    <!-- Settings Dialog -->
    <v-dialog v-model="dialogSettings" width="700">
      <v-card>
        <component
          :is="currentSettings"
          class="mt-4"
          v-model="action.settings"
        ></component>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn color=" darken-1" text @click="dialogSettings = false">
            CLOSE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Test Dialog -->
    <v-dialog v-model="dialogTest" fullscreen>
      <v-card>
        <v-toolbar flat dark color="primary">
          <v-btn icon @click.stop="dialogTest = false">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-toolbar-title> Testing </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon @click="savePlayer">
            <v-icon>mdi-content-save </v-icon>
          </v-btn>
          <v-divider class="mx-4" vertical></v-divider>
          <v-btn icon @click="playTest" 
          :loading="executing"
          :disabled="executing">
            <v-icon>mdi-play</v-icon>
          </v-btn>
        </v-toolbar>

        <v-container fluid>
          <v-row>
            <v-col cols="6">
              <v-card>
                <v-toolbar flat color="blue lighten-5">
                  <v-toolbar-title>Input </v-toolbar-title>  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-btn small>Import from Queue</v-btn>
                </v-toolbar>

                <vue-monaco-editor
                  v-model="inputSample"
                  :options="fullOpts"
                  height="300px"
                  language="csharp"
                  loading="false"
                />
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-toolbar flat color="blue lighten-5">
                <v-toolbar-title>Output </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-chip color="success" v-if="!error && !skipped" label>OK</v-chip>
                <v-chip color="error" v-if="error && !skipped" label>Error</v-chip>
                <v-chip color="warning" v-if="skipped" label>Skipped</v-chip>
              </v-toolbar>
              <v-card>
                <vue-monaco-editor
                  height="300px"
                  language="json"
                  loading="false"
                  :options="readOnlyOpts"
                  v-model="JSON.stringify(output, null, 2)"
                />
              </v-card>
            </v-col>
          </v-row>
          <divider></divider>
          <v-row>
            <v-col cols="4">
              <v-toolbar flat color="blue lighten-5">
                <v-toolbar-title>Settings </v-toolbar-title>
              </v-toolbar>
              <v-card flat>

                <v-text-field placeholder="Condition" label="Condition" v-model="condition">

                </v-text-field>
              </v-card>
              <v-spacer class="mt-4"></v-spacer>
              <v-card>
                <vue-monaco-editor
                  v-model="inputSettings"
                  height="300px"
                  language="csharp"
                  loading="false"
                  :options="fullOpts"
                />
              </v-card>
            </v-col>
            <v-col cols="8">
              <v-card>
                <v-toolbar flat color="blue lighten-5">
                  <v-toolbar-title>Logs </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn small @click="clearLogs">Clear</v-btn>
                </v-toolbar>
                <v-data-table
                  height="300px"
                  :hide-default-footer="true"
                  :headers="logHeaders"
                  :items="logs"
                  dense
                  item-key="date"
                ></v-data-table>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-dialog>

    <!-- Action Design -->
    <v-row>
      <v-col>
        <v-card>
          <v-toolbar flat outlined dense>
            <v-toolbar-title>Modified at: {{ getFormattedDate( action.modifiedOn,"YYYY-MM-DD HH:mm:ss") }} </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn icon @click="save">
              <v-icon>mdi-content-save </v-icon>
            </v-btn>
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn icon @click="openSettings">
              <v-icon>mdi-cog</v-icon> </v-btn
            ><v-divider class="mx-4" vertical></v-divider>
            <v-btn icon @click="openTest">
              <v-icon>mdi-flask-outline</v-icon>
            </v-btn>
            <v-divider class="mx-4" vertical></v-divider>
            <v-btn icon @click="saveAndRunTest"  :loading="executing" :disabled="executing">
              <v-icon>mdi-script-text-play</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card>
            <component
              :is="currentComponent"
              v-model="action.settings"
              class="mt-4"
            ></component>
          </v-card>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from "vue";
import CommonEditor from "../shared/CommonEditor";
import CsharpCode from "./actionTypes/csharpCode.vue";
import csharpCodeSettings from "./actionTypes/csharpCodeSettings.vue";
import MappingSettings from "./actionTypes/mappingSettings.vue";
import Mapping from "./actionTypes/mapping.vue";
import { defineComponent } from "vue";
import restDataService from "../../extensions/restDataService";
import objectStorage from "../../services/objectStorage";
import userService from '../../services/user';

const components = [CommonEditor, CsharpCode, Mapping];
const settings = [MappingSettings, csharpCodeSettings];

const cmp = defineComponent({
  name: "ActionsDesign",
  components: {
    CommonEditor,
    CsharpCode,
    Mapping,
    MappingSettings,
    csharpCodeSettings,
  },
  props: ["value"],
  setup: function () {},
  beforeMount: async function () {
    this.id = this.$route.params.id;
    this.action = await this.getItem(this.id, true);
    this.output = {
      hint: "click play button to run the code!",
    };

    this.currentComponent = components.find(
      (x) => x.name.toLowerCase() == this.action.type.toLowerCase(),
      this
    );

    this.currentSettings = settings.find(
      (x) =>
        x.name.toLowerCase() == this.action.type.toLowerCase() + "settings",
      this
    );

    await this.loadPlayer();
    this.editorSettings = await objectStorage.get("editorSettings", this.id);
  },
  methods: {
    loadPlayer: async function () {
      this.inputSample = JSON.stringify(
        (await objectStorage.get("inputSample", this.id)) ?? {},
        null,
        2
      );
      this.inputSettings = JSON.stringify(
        (await objectStorage.get("inputSettings", this.id)) ?? {},
        null,
        2
      );
      this.condition=await objectStorage.get("condition", this.id);
    },
    saveAndRunTest: async function () {
      await this.save();
      await this.playTest(null);
      await this.openTest(null);
    },
    savePlayer: async function () {
      try {
        await objectStorage.save(
          "inputSample",
          this.id,
          JSON.parse(this.inputSample)
        );
        await objectStorage.save(
          "inputSettings",
          this.id,
          JSON.parse(this.inputSettings)
        );
        await objectStorage.save(
          "condition",
          this.id,
          this.condition
        );
      } catch (e) {
        this.showError("Error saving settings. Check your json syntax.");
      }
    },
    save: async function () {
      await this.update(this.action);
    },
    playTest: async function (event) {
      this.executing=true;
      let user = userService.user;
      let request={
        data:JSON.parse(this.inputSample),
        settings:JSON.parse(this.inputSettings),
        actionId:this.id,
        tenantId:user.tenant,
        condition:this.condition
      };
      console.log(request);
      
      let result= this.simplifyResponse(await this.apiCall('/api/lowcode', "POST", request, {}));
      console.log(result);
      
      this.error=!result.isSuccess;
      this.skipped=result.isSkipped;
 
      result.logEntries?.forEach(x=>{
        this.logs.unshift(x);
      });

      this.output = result.after;
      this.executing=false;
    },
    openSettings: async function (event) {
      this.dialogSettings = true;
    },
    openTest: async function (event) {
      this.dialogTest = true;
      this.executing=false;
    },
    clearLogs: async function(event){
      this.logs=[];
    }
  },
  data: function () {
    return {
      currentComponent: "",
      currentSettings: "",
      inputSample: "",
      inputSettings: "",
      editorSettings: "",
      error:null,
      skipped:null,
      action: {},
      dialogSettings: false,
      dialogTest: false,
      output: {},
      condition:"",
      executing:false,
      fullOpts: {
        minimap: { enabled: false },
      },
      readOnlyOpts: {
        readOnly: true,
        minimap: { enabled: false },
      },
      logHeaders: [
        { text: "date", align: "start", sortable: false, value: "date" },
        { text: "message", align: "start", sortable: false, value: "message" },
      ],
      logs: [],
    };
  },
});

export default Vue.extend(cmp).extend(restDataService("action"));
</script>
