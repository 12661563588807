import axios from '../plugins/axios'
import {httpClient} from "../plugins/extension";

class ComboboxService
{
    constructor(axios) {
        this.axios=axios;
    }
    async GetActions (params){
        let response=httpClient.simplifyResponse(await  httpClient.apiCall("/api/action/list", "POST", params,{}));
        let result=[];
        response?.payload?.content?.forEach(x => {
            result.push({
                text: x.name,
                value: x.id
            });
        });
        return result;
    }

    async GetDestinations (params){
        let response=httpClient.simplifyResponse(await  httpClient.apiCall("/api/destinations/list/json", "GET", params,{}));
        let destinationEnumJson = response.payload.content.destinations;
        let result=[];
        destinationEnumJson.forEach(dest => {
            result.push({
                text: dest.Name,
                value: dest.Name
            });
            result.push({
                text: dest.Name + " [ID Value]",
                value: dest._id
            });
        });
        return result;
    }
}

const comboboxService= new ComboboxService(axios);
export default comboboxService;
