import Vue from "vue";
import {defineComponent} from "vue"

function dataService(entity)
{
   return  defineComponent({
        data: function(){
            return {
                entityName:entity,
                items:[],
                searchFilter:{}
            };
        },
        methods:{
            getData: async function () {
                
                let response = await this.apiCall(
                    `/api/${this.entityName}/list`,
                    "POST",
                    {},
                    {}
                  );
                  if (response.status == 200) {
                    this.items = response.data.payload.content;
                  }
                  else
                  {
                    //todo: manage error
                  }

                return this.items;
            },
            getItem: async function (id, force) {
                let item =null;
                if(!force){
                    item = this.items.find(
                    (x) => x.id == this.editingItem.id,
                    this
                  );
                }
                if(item==null)
                {
                    let response = await this.apiCall(
                        `/api/${this.entityName}/${id}`,
                        "GET",
                        {},
                        {}
                      );
                      if (response.status == 200) {
                        item = response.data.payload.content;
                      }
                }

                return item;
            },
            insert: async function (data) {
                let response = await this.apiCall(
                    `/api/${this.entityName}`,
                    "POST",
                    data,
                    {}
                  );
            
                  if (response.status == 200) {
                    let replacement = response.data.payload.content;
                    this.items.push(replacement);
                    this.editingItem.id = replacement.id;
                    this.showSuccess(`Item added with id ${replacement.id}`);
                  }
            },
            update: async function (data) {
                let response = await this.apiCall(
                    `/api/${this.entityName}/${data.id}`,
                    "PUT",
                    data,
                    {}
                  );
                  if (response.status == 200) {
                    let replacement = response.data.payload.content;
                    let idx = this.items.findIndex(
                      (x) => x.id == data.id,
                      this
                    );
                    console.log(idx);
                    this.items.splice(idx, 1, replacement);
                    this.showSuccess("Item updated");
                  }
            },
            deleteItem: async function (data) {
                let response = await this.apiCall(
                    `/api/${this.entityName}/${data.id}`,
                    "DELETE",
                    {},
                    {}
                  );
                  if (response.status == 200) {
                    this.showSuccess("Item deleted");
                    let idx = this.items.findIndex(
                      (x) => x.id == data.id,
                      this
                    );
                    this.items.splice(idx, 1);//TODO: in caso di paginazione non è detto che ci sia
                  } 
                  else 
                  {
                    this.showError(response.data.metadata.uiMessages.errors[0].uiMessages);
                  }
            },  
        }
    });
}
export default dataService;